class SEO {
    constructor(apiConfig, http) {
        const { siteInfo } = apiConfig;
        const { storeId } = siteInfo;
        this.apiConfig = apiConfig;
        this.http = http;
        this.path = `/wcs/resources/store/${storeId}/seo`;
    }

    get(template, vanityUrl, config = {}) {
        const params = {
            path: vanityUrl,
        };
        return this.http.get(`${this.path}/${template}`, { params, ...config }).then(resp => {
            const { data, status } = resp;
            //attempt to parse response
            if (status === 200 && data && data !== '') {
                const { seoData = [] } = data;
                const [seo = {}] = seoData;
                const { description, breadCrumbs, title, keyword: keywords, canonical, h1Tag, noIndex } = seo;
                const seoResponse = {
                    title,
                    description,
                    keywords,
                    canonical,
                    breadcrumbs: (breadCrumbs || []).map((crumb, idx, arr) => {
                        const { anchorText: label, URL: href } = crumb;
                        return {
                            label,
                            //always exclude last link in breadcrumbs
                            href: idx === arr.length - 1 ? undefined : href,
                            gtmValue: 'click',
                            id: label,
                        };
                    }),
                    h1Tag,
                    robots: noIndex === 'true',
                };
                return seoResponse;
            } else {
                return Promise.reject({});
            }
        });
    }

    getWordcloud(template, vanityUrl, config = {}) {
        const params = {
            path: vanityUrl,
        };
        return this.http.get(`${this.path}/wordcloud/${template}`, { params, ...config }).then(resp => {
            const { data, status } = resp;
            //attempt to parse response
            if (status === 200 && data && data !== '') {
                return data;
            } else {
                return Promise.reject({});
            }
        });
    }
}

export default SEO;
