import React from 'react';
import { connect } from 'react-redux';
import { forceCheck } from 'react-lazyload';
import debounce from 'lodash/debounce';

import { withError } from '../../shared/components/error-boundary';
import AboveTheFoldOnlyServerRender from '../../shared/components/above-the-fold-only-server-render';

import { trackCustomMetric } from '../../metrics/track-params';

import { setAnalyticsData } from '../../metrics/metrics-helpers';

/**
 * Temporary helper function to show dual CTA for kit products. Remove when new design is implemented for all products
 * @param {*} productInitial
 * @returns
 */
export const showDualCtaForKit = productInitial => {
    try {
        const { enrollmentKit, topCategory } = productInitial || {};
        const { btnProps, kit } = enrollmentKit || {};
        const { to, children: ctaText } = btnProps || {};
        const category = topCategory?.toLowerCase();

        return (
            !enrollmentKit ||
            (!kit?.catEntryId &&
                to &&
                typeof ctaText === 'string' &&
                ctaText.toLowerCase().indexOf('subscribe') > -1 &&
                (category == 'printers' || category == 'laptops'))
        );
    } catch (e) {
        return !productInitial?.enrollmentKit;
    }
};

/**
 * https://www.npmjs.com/package/react-lazyload
 * trigger force check when components update,eg from async calls, in case something is near
 * the threshold but the user hasn't started to scroll yet
 */
const debounceLazyLoadCheck = debounce(() => {
    forceCheck();
}, 300);

const themeEnabled = state => {
    const { slugInfo } = state || {};
    const { theme } = slugInfo || {};
    return theme?.key;
};

const withPageComponents = (Component, options) => {
    const {
        components = [],
        stateComponents = [],
        device,
        customBreakpoint,
        storeDomain,
        storeEnvironment,
        siteConfig,
        location,
        withErrors,
        seo,
        addOns,
        analyticsData,
        analyticsOptions,
        disableMemoization,
        gqlClientLoadStatus,
        lazyLoadSettings: lazyLoadOptions,
        proxyHost,
        isPreviewMode,
        customFields,
        allowOwnProps,
    } = options || {};

    const pageComponents = props => {
        //allow lazyLoad settings to be passed in props or in HOC options param
        const { lazyLoadSettings = lazyLoadOptions, ...restProps } = props;
        if (lazyLoadSettings) {
            debounceLazyLoadCheck();
            return (
                <AboveTheFoldOnlyServerRender {...lazyLoadSettings}>
                    <Component {...restProps} />
                </AboveTheFoldOnlyServerRender>
            );
        }
        return <Component {...restProps} />;
    };

    const mapStateToProps = (state, ownProps) => {
        const { slugInfo, storeDomain: storeDomainState, storeEnvironment: environment } = state;
        const { vanityUrl, callCenterNumber, gqlAsyncClientLoad, gqlClientLoad, nextQuery, templateKey, previewMode } =
            slugInfo || {};
        const isGQLClientFetch = gqlClientLoad || gqlAsyncClientLoad;
        const templateThemesEnabled = themeEnabled(state);
        let propObj = components.reduce(
            (r, componentKey) => {
                const component = slugInfo.components[componentKey];
                const { analyticsData } = slugInfo;
                if (allowOwnProps && componentKey in ownProps) {
                    return r;
                }
                //TODO: temp fix to hide pdpShadowCTO on small devices
                if (componentKey === 'pdpShadowCTO' && state.ui.width < 1024) {
                    return r;
                }

                if (componentKey === 'pdpSpecialOffers' && showDualCtaForKit(slugInfo.components?.productInitial)) {
                    return r;
                }
                //TODO: this needs to be removed in favor of moving Omen richt content to the espot
                if (componentKey === 'pdpRichContent' && templateThemesEnabled) {
                    return r;
                }

                if (component) {
                    r[componentKey] = setAnalyticsData({
                        vanityUrl,
                        componentKey,
                        component,
                        analyticsData,
                        slugInfo,
                        options: { disableMemoization, ...analyticsOptions },
                    });
                }
                return r;
            },
            { category: slugInfo.category, vanityUrl, callCenterNumber, templateKey, proxyHost, isBot: state.ui.isBot },
        );
        stateComponents.forEach(componentKey => {
            if (componentKey in state && !(componentKey in propObj)) {
                propObj[componentKey] = state[componentKey];
            }
        });

        if (device || (customBreakpoint && state.ui.customBreakpoints[customBreakpoint])) {
            propObj.device = (customBreakpoint && state.ui.customBreakpoints[customBreakpoint]) || state.ui.device;
            propObj.width = state.ui.width;
            propObj.innerWidth = state.ui.innerWidth;
        }
        if (storeDomain) {
            propObj.storeDomain = storeDomainState;
        }
        if (storeEnvironment) {
            propObj.storeEnvironment = environment;
        }
        if (isPreviewMode) {
            propObj.previewMode = previewMode;
        }
        if (siteConfig) {
            propObj.siteConfig = state.siteConfig;
        }
        if (location) {
            propObj.location = state.router.location;
        }
        if (withErrors) {
            propObj.errors = slugInfo.errors;
        }
        if (analyticsData) {
            propObj.analyticsData = state.slugInfo && state.slugInfo.analyticsData;
        }
        if (seo) {
            propObj.seo = state.slugInfo && state.slugInfo.seo;
        }
        if (addOns) {
            propObj.addOns = state.productData && state.productData.addOns;
        }
        if (gqlClientLoadStatus && isGQLClientFetch) {
            propObj.isClientGQLLoading = !(gqlAsyncClientLoad && !nextQuery);
            propObj.gqlClientLoad = gqlClientLoad;
            propObj.gqlAsyncClientLoad = gqlAsyncClientLoad;
        }
        if (proxyHost) {
            propObj.proxyHost = state.proxyHost;
        }
        if (customFields) {
            propObj.customFields = state.slugInfo && state.slugInfo.customFields;
        }
        return propObj;
    };

    const mapDispatchToProps = dispatch => {
        //TODO: add action for fetching components async
        return {
            trackCustomMetric: (eventName, params) => dispatch(trackCustomMetric(eventName, params)),
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(withError(pageComponents));
};

export default withPageComponents;
